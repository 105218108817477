<template>
  <div class="input-avatar">
    <v-img
      :aspect-ratio="aspectRatio"
      :height="height"
      :lazy-src="lazySrc"
      :max-height="maxHeight"
      :max-width="maxWidth"
      :src="internalSrc"
      :width="width"
      class="rounded"
    >
      <template v-slot:default>
        <div class="upload-control">
          <input
            ref="file"
            accept="image/*"
            style="display: none"
            type="file"
            @change="selectFile()"
          />
          <v-btn
            block
            class="upload-control--btn"
            color="grey lighten-2"
            icon
            x-small
            @click="$refs.file.click()"
          >
            <v-icon>mdi-image-edit</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular
            color="grey lighten-2"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <tp-text-field
      :hide-details="hideDetails || !required"
      :validate="required ? 'required' : ''"
      :value="file"
      class="mt-2"
    ></tp-text-field>
  </div>
</template>

<script>
import { showModalAlertError } from "@/core/composables";

export default {
  props: {
    aspectRatio: {
      type: [Number, String]
    },
    height: {
      type: [Number, String]
    },
    requiredHeight: {
      type: Number,
      default: null
    },
    hideDetails: {
      type: [Boolean, String]
    },
    lazySrc: {
      type: String,
      default: require("@/core/assets/images/default-image.png")
    },
    maxHeight: {
      type: [Number, String]
    },
    maxWidth: {
      type: [Number, String]
    },
    src: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String]
    },
    requiredWidth: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      img: null
    };
  },
  watch: {
    src: {
      handler(value) {
        this.file = value;
      },
      immediate: true
    }
  },
  computed: {
    internalSrc() {
      return this.src || require("@/core/assets/images/default-image.png");
    }
  },
  methods: {
    selectFile() {
      this.file = this.$refs.file.files;
      const img = new Image();
      const objectUrl = URL.createObjectURL(this.file[0]);

      const emitChangeImageAndUploadFile = () => {
        this.$emit("change", objectUrl);

        this.uploadFile();
      };

      if (this.requiredWidth && this.requiredHeight) {
        img.onload = () => {
          if (
            img.width === this.requiredWidth &&
            img.height === this.requiredHeight
          ) {
            emitChangeImageAndUploadFile();
          } else {
            showModalAlertError(this, {
              title: "Kích thước hình ảnh không hợp lệ",
              message: `Vui lòng tải lên hình ảnh có kích thước ${this.requiredWidth} x ${this.requiredHeight} px.`
            });
          }
        };
        img.src = objectUrl;
      } else if (this.requiredWidth && !this.requiredHeight) {
        img.onload = () => {
          if (img.width === this.requiredWidth) {
            emitChangeImageAndUploadFile();
          } else {
            showModalAlertError(this, {
              title: "Kích thước hình ảnh không hợp lệ",
              message: `Vui lòng tải lên hình ảnh có chiều dài ${this.requiredWidth} px.`
            });
          }
        };
        img.src = objectUrl;
      } else if (!this.requiredWidth && this.requiredHeight) {
        img.onload = () => {
          if (img.height === this.requiredHeight) {
            emitChangeImageAndUploadFile();
          } else {
            showModalAlertError(this, {
              title: "Kích thước hình ảnh không hợp lệ",
              message: `Vui lòng tải lên hình ảnh có chiều cao ${this.requiredHeight} px.`
            });
          }
        };
        img.src = objectUrl;
      } else {
        emitChangeImageAndUploadFile();
      }

      // reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    },

    async uploadFile() {
      const formData = new FormData();

      Array.from(this.file).forEach(f => {
        formData.append("file", f);
      });

      this.$emit("updateFormData", formData);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-avatar {
  display: flex;
  flex-direction: column;
  width: 100%;

  .v-image {
    border: 1px solid #e5e5e5;
  }

  ::v-deep .v-input__slot {
    display: none;
  }
}
.upload-control {
  align-items: flex-end;
  display: flex;
  height: 100%;
  &--btn {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-radius: 0 !important;
  }
}
</style>
